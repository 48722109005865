.auth-wrapper {
  > .full-height {
    height: 100vh;
    display: flex;
  }

  .left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    background-color: $white;

    h3 {
      font-family: $base-font;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #04072f;
    }
    p {
      font-family: $base-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: #04072f;
      padding-left: 20px;
      padding-right: 20px;
    }
    * {
      margin: 10px 0;
    }
  }
  .right-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    background-color: #f5f5fd !important;
    flex: 1;

    .main-heading {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
    }

    .sub-heading {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #5c5e78;
    }

    .register {
     
      .login-redirect {
        text-align: center !important;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 50px;
        justify-content: center;
        color: #5c5e78;
        a {
          margin: 0px 3px;
        }
      }
    }
    .login {
      width: 400px;
      .forget-pass {
        text-align: right;
        a {
          margin: 0px 3px;
        }
      }
      .register-redirect {
        text-align: center !important;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 50px;

        justify-content: center;
        color: #5c5e78;
        a {
          margin: 0px 3px;
        }
      }
    }
    .forget-password {
      width: 400px;
      .forget-pass {
        text-align: right;
        a {
          margin: 0px 3px;
        }
      }
      .forget-password-redirect {
        text-align: center !important;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        justify-content: center;
        color: #5c5e78;
        a {
          margin: 0px 3px;
        }
      }
    }
    
  }

  .link {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1px;
    color: #116af4;
  }

  .small-link {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #116af4;
  }

  .form-label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #5c5e78;
  }


  .form-submit-btn {
    padding: 8px 20px;
    position: static;
    width: 393px;
    height: 44px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
  }

  .upload-text {
    color: #116af4;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    // background-color: yellow;
    // border-style: dashed;
    // border-color: #6da3f8;
    // border-width: 1px;
    // border: 1px solid #6DA3F8;
  }

  .upload {
    width: 100%;
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    border-color: #6da3f8;
    color: #116af4;
    border-radius: 6px;
    padding: 12px 10px;
    border-width: 1px;
    border-style: dashed;
  }
}

.ant-upload.ant-upload-select-picture-card {
  border-width: 0px !important;
  background-color: #f5f5fd !important;
}

.ant-upload.ant-upload-select-picture-card {
  height: 50px;
}
