$base-color: #116af4;
$base-font: "Inter";
$base-font-size: 14px;
$base-font-weight: 400;
$selected-font-weight: 500;
$base-line-height: 17px;
$button-gradient-color: linear-gradient(273.43deg, #116af4 0%, #45aff5 99.17%);
$secondary-base-color: #6da3f8;
$header-height: 60px;
$white: #fff;
